import React, { useEffect, useState } from 'react';

const FloatingWhatsAppBtn = () => {
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const isFooterVisible = entries[0].isIntersecting;
        setShowButton(!isFooterVisible);
      },
      { threshold: 0.1 }
    );

    const footerElement = document.querySelector('footer');
    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    showButton && (
      <div className="fixed bottom-4 left-4 z-[999]">
        <button
          className="bg-[#25D366] text-white text-sm md:text-md font-semibold py-2 px-2 md:px-4 rounded-full shadow-lg hover:bg-[#1DAA59] transition duration-300 flex items-center gap-2"
          onClick={() => (window.location.href = 'https://wa.me/917010603063?text=Hello!')}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp Icon"
            className="w-5 h-5"
          />
          <span className="hidden sm:inline">Chat with Us</span>
        </button>
      </div>
    )
  );
};

export default FloatingWhatsAppBtn;
